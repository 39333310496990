<template>
  <div class="advanced-toolbar">
    <el-row type="flex" style="padding: 0 10px">
      <el-col :span="6">
        <el-form-item>
          <el-input
            v-model="search"
            :placeholder="__('Search')"
            @input="handleSearch"
            class="searchInput"
          >
            <img
              slot="suffix"
              :src="require('@/assets/icons/icon-search.svg')"
              class="input-icon"
          /></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" style="padding-left: 20px">
        <el-form-item>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            align="right"
            :start-placeholder="__('Start Date')"
            :end-placeholder="__('End Date')"
            value-format="yyyy-MM-dd"
            :format="displayDateFormat"
            class="dateRangeSelect"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="padding: 0 10px">
      <el-col :span="8" style="padding-right: 20px">
        <service-provider-dropdown
          v-if="hasAccessToMultipleServiceProviders"
          :default-sp-id="selectedServiceProvider"
          @sp-id-changed="handleChangeSpId"
        ></service-provider-dropdown>
      </el-col>
      <el-col :span="8" style="padding-right: 20px">
        <account-dropdown
          v-if="
            hasAccessToMultipleServiceProviders ||
              hasMultipleAccountsInServiceProvider(selectedServiceProvider)
          "
          @ac-id-changed="handleChangeAcId"
          :selected-sp-id="selectedServiceProvider"
          :default-ac-id="selectedAccounts"
          :multi-select="true"
        ></account-dropdown>
      </el-col>
      <el-col :span="8">
        <task-dropdown
          @task-id-changed="handleChangeTaskId"
          :selected-ac-id="selectedAccounts"
          :selected-sp-id="selectedServiceProvider"
          :default-task-id="selectedTaskIds"
          :multi-select="true"
          :task-type="taskFilter"
        ></task-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import ServiceProviderDropdown from "./ServiceProviderDropdown";
import AccountDropdown from "./AccountDropdown";
import TaskDropdown from "./TaskDropdown";
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import { stringDate } from "@/utils/time";
import PaginationToolbar from "@/components/PaginationToolbar";

export default {
  name: "AdvancedToolbar",
  components: { TaskDropdown, AccountDropdown, ServiceProviderDropdown },
  mixins: [PaginationToolbar],
  computed: {
    ...mapState("app", {
      serviceProvidersAccessibleToUser: state =>
        state.userAccountSwitcherOptions,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("tasks", {
      taskFilter: state => state.taskFilter
    }),

    ...mapGetters("app", {
      hasAccessToMultipleServiceProviders:
        "hasAccessToMultipleServiceProviders",
      hasMultipleAccountsInServiceProvider:
        "hasMultipleAccountsInServiceProvider",
      userAccountsFor: "userAccountsFor"
    }),

    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat"
    }),

    selectedServiceProvider: {
      get() {
        let sp = _.find(this.serviceProvidersAccessibleToUser, {
          sp_id:
            this.selectedSpId === "all"
              ? this.selectedServiceProviderId === "all"
                ? this.serviceProvidersAccessibleToUser[0].sp_id
                : this.selectedServiceProviderId
              : this.selectedSpId
        });
        return sp.sp_id;
      },

      set(val) {
        this.selectedAccounts = ["all"];
        this.selectedSpId = val;
      }
    },

    selectedAccounts: {
      get() {
        let accounts = this.userAccountsFor(this.selectedServiceProvider);
        if (accounts.length === 1) {
          return [accounts[0].ac_id];
        } else {
          return _.cloneDeep(this.selectedAcIds);
        }
      },
      set(val) {
        this.selectedTaskIds = !val.length ? [] : ["all"];
        this.selectedAcIds = _.cloneDeep(val);
      }
    },

    pickerOptions() {
      return {
        shortcuts: [
          {
            text: __("Today"),
            onClick(picker) {
              const today = stringDate(moment());
              picker.$emit("pick", [today, today]);
            }
          },
          {
            text: __("Yesterday"),
            onClick(picker) {
              const yesterday = stringDate(moment().subtract(1, "day"));
              picker.$emit("pick", [yesterday, yesterday]);
            }
          },
          {
            text: __("Last 7 Days"),
            onClick(picker) {
              const today = moment();
              const yesterday = today.clone().subtract(1, "day");
              const sevenDaysBefore = today.clone().subtract(7, "days");
              picker.$emit("pick", [
                stringDate(sevenDaysBefore),
                stringDate(yesterday)
              ]);
            }
          },
          {
            text: __("This Month"),
            onClick(picker) {
              const monthStart = stringDate(moment().startOf("month"));
              const monthEnd = stringDate(moment().endOf("month"));
              picker.$emit("pick", [monthStart, monthEnd]);
            }
          },
          {
            text: __("Last Month"),
            onClick(picker) {
              const lastMonthDay = moment().subtract(1, "months");
              const lastMonthStart = stringDate(lastMonthDay.startOf("month"));
              const lastMonthEnd = stringDate(lastMonthDay.endOf("month"));
              picker.$emit("pick", [lastMonthStart, lastMonthEnd]);
            }
          },
          {
            text: __("Last 3 Months"),
            onClick(picker) {
              const end = stringDate(
                moment()
                  .subtract(1, "months")
                  .endOf("month")
              );
              const start = stringDate(
                moment()
                  .subtract(3, "months")
                  .startOf("month")
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("This Quarter"),
            onClick(picker) {
              const end = stringDate(moment().endOf("quarter"));
              const start = stringDate(moment().startOf("quarter"));
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("Last Quarter"),
            onClick(picker) {
              const lastQuarterDate = moment().subtract(1, "quarters");
              const end = stringDate(lastQuarterDate.endOf("quarter"));
              const start = stringDate(lastQuarterDate.startOf("quarter"));
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("Last 6 Months"),
            onClick(picker) {
              const end = stringDate(
                moment()
                  .subtract(1, "months")
                  .endOf("month")
              );
              const start = stringDate(
                moment()
                  .subtract(6, "months")
                  .startOf("month")
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: __("This Year"),
            onClick(picker) {
              const end = stringDate(moment().endOf("year"));
              const start = stringDate(moment().startOf("year"));
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      };
    }
  },
  data() {
    return {
      dateRange: [],
      selectedSpId: "all",
      selectedAcIds: ["all"],
      selectedTaskIds: ["all"]
    };
  },
  created() {
    let today = stringDate(moment());
    this.dateRange = [today, today];
  },
  methods: {
    handleSearch() {
      this.debounceFetchContents(500);
    },
    fetchContentItems(notShowLoader = false) {
      return new Promise((resolve, reject) => {
        if (!this.selectedTaskIds.length) {
          resolve();
        } else {
          if (!_.isEmpty(this.search)) {
            return this.contentApi({
              search: this.search,
              fetch_all: 1,
              task_type: this.taskFilter,
              sp_id: this.selectedServiceProvider,
              ac_ids: this.selectedAccounts.join(","),
              task_ids: this.selectedTaskIds.join(","),
              start_date: this.dateRange.length > 1 ? this.dateRange[0] : 0,
              end_date: this.dateRange.length > 1 ? this.dateRange[1] : 0,
              notShowLoader
            })
              .then(data => {
                this.total =
                  (data.meta && data.meta.pagination.total) || data.data.length;
                resolve();
              })
              .catch(err => reject(err));
          } else {
            return this.contentApi({
              fetch_all: 1,
              task_type: this.taskFilter,
              sp_id: this.selectedServiceProvider,
              ac_ids: this.selectedAccounts.join(","),
              task_ids: this.selectedTaskIds.join(","),
              start_date: this.dateRange.length > 1 ? this.dateRange[0] : 0,
              end_date: this.dateRange.length > 1 ? this.dateRange[1] : 0,
              notShowLoader
            })
              .then(data => {
                this.total =
                  (data.meta && data.meta.pagination.total) || data.data.length;
                resolve();
              })
              .catch(err => reject(err));
          }
        }
      });
    },
    handleChangeSpId(val) {
      this.selectedServiceProvider = val;
    },
    handleChangeAcId(val) {
      this.selectedAccounts = val;
    },
    handleChangeTaskId(val) {
      this.selectedTaskIds = val;
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler: function(val) {
        this.$emit("dateRangeChanged", val);
        this.debounceFetchContents(1000);
      }
    },
    selectedServiceProvider: {
      handler: function() {
        this.debounceFetchContents(1000);
      }
    },
    selectedAccountId: {
      immediate: true,
      handler(val) {
        this.selectedSpId = this.selectedServiceProviderId;
        this.selectedAcIds = [val];
      }
    },
    selectedAccounts: {
      handler: function() {
        this.debounceFetchContents(1000);
      }
    },
    selectedTaskIds: {
      handler: function() {
        this.debounceFetchContents(1000);
      }
    }
    // $route: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     if (this.paramsHasAcId) {
    //       this.selectedAcIds = [val.params.ac_id];
    //     }
    //   }
    // }
  }
};
</script>

<style scoped lang="scss"></style>
